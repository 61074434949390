@font-face {
  font-family: 'Avenir Next';
  font-weight: 200;
  src: url('./assets/AvenirNext-UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 400;
  src: url('./assets/AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 500;
  src: url('./assets/AvenirNext-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 800;
  src: url('./assets/AvenirNext-Bold.ttf') format('truetype');
}

html, body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
}

.bodyContainer {
  width: 100vw;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 100000000000000;
  pointer-events: none;
}

.rotateOverlay, .rotateOverlayText {
  display: none;
}

@media (orientation: landscape) and (max-width: 812px) {
  .rotateOverlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 100000000000000;
    pointer-events: none;
  }

  .rotateOverlayText {
    display: flex;
    width: 60%;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 2.5vw;
    line-height: 5vw;
    color: #fff;
    margin-top: 7vh;
    margin-bottom: 0;
    text-align: center;
  }
}