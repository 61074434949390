.footer {
  padding-left: 5%;
  padding-right: 5%;
  width: 90%;
  height: auto;
  background-color: #DE692C;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 8vh;
}

.footerLinks {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footerLinksTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 7vw;
  align-self: flex-start;
}

.footerLinksColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  width: auto;
  margin-right: 7vw;
}

.footerLink {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1.2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 4vh;
  align-self: flex-start;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.footerLink:hover {
  opacity: 0.7;
}

.copyrightBlock {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
  margin-bottom: 4vh;
}

.copyrightText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1vh;
  text-align: right;
}

.designedByContainer {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.designedByText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5vw;
}

.designedByLink {
  width: 4vw;
  height: auto;
  text-decoration: none;
}

.designedByImg {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.designedByImg:hover {
  opacity: 0.7;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .footer {
    width: 86%;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 6vh;
    padding-bottom: 3vh;
  }
  
  .footerLinksTitle {
    display: none;
  }
  
  .footerLinksColumn {
    margin-right: 9vw;
  }
  
  .footerLink {
    font-size: 2.3vw;
  }
  
  .copyrightBlock {
    margin-bottom: 4vh;
  }
  
  .copyrightText {
    width: 40vw;
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-bottom: 2vh;
  }
  
  .designedByText {
    font-size: 1.8vw;
    line-height: 3.6vw;
    margin-right: 0.8vw;
  }
  
  .designedByLink {
    width: 8vw;
  }
}

@media (orientation: portrait) and (max-width: 1024px) {
  .footer {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    height: auto;
    background-color: #DE692C;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 4vh;
    padding-bottom: 2.5vh;
  }
  
  .footerLinks {
    display: none;
  }
  
  .copyrightBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-end;
    margin-bottom: 0;
  }
  
  .copyrightText {
    font-size: 2.8vw;
    line-height: 5.6vw;
    margin-bottom: 2vh;
    text-align: left;
    width: 100%;
  }
  
  .designedByText {
    font-size: 2.8vw;
    line-height: 5.6vw;
    margin-right: 1.5vw;
  }
  
  .designedByLink {
    width: 12vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .footer {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
    height: auto;
    background-color: #DE692C;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 4vh;
    padding-bottom: 2.5vh;
  }
  
  .footerLinks {
    display: none;
  }
  
  .copyrightBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-end;
    margin-bottom: 0;
  }
  
  .copyrightText {
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 2vh;
    text-align: left;
    width: 100%;
  }
  
  .designedByText {
    font-size: 4vw;
    line-height: 8vw;
    margin-right: 1.5vw;
  }
  
  .designedByLink {
    width: 15vw;
  }
}