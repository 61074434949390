.addAnimal {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5vh;
}

.addAnimalImageLoader {
  width: 45%;
  height: 90vh;
}

.addAnimalInputLineTitle {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.addAnimalInputLine {
  width: 100%;
  height: 50px;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.animalsAdminAddInput {
  display: none;
}

.addAnimalImageContainer {
  height: calc(90vh - 1vh - 50px);
  width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  overflow-y: scroll;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}

.addAnimalImageContainerImageBox {
  display: inline-block;
  width: 27%;
  margin-right: 3%;
  margin-left: 3%;
  height: 24vh;
  position: relative;
  border-radius: 8px;
  margin-bottom: 1.5vh;
}

.addAnimalImageContainerImage {
  width: 100%;
  height: 18vh;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}

.addAnimalImageContainerBoxDelete {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 20px;
  height: 20px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #DE692C;
  border-radius: 15px;
  z-index: 1000000;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.addAnimalImageContainerBoxDelete:hover {
  transform: scale(0.9);
}

.addAnimalFormContainer {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10vh;
}

.addAnimalFormTitle {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
}

.addAnimalCheckboxLine {
  width: 100%;
  height: auto;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.addAnimalCollabItem {
  width: 48% !important;
}

.addAnimalFormSeparator {
  height: 3vh;
}

.animalsAdminAddFormButton {
  width: auto;
  align-self: flex-end;
}

.animalsMobileSubmitButton {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .addAnimalCollabItem {
    width: 40% !important;
  }

  .addAnimalCollabText {
    font-size: 2vw !important;
    margin-left: 1.5vw !important;
  }

  .addAnimalImageContainerImageBox {
    width: 86%;
    margin-right: 7%;
    margin-left: 7%;
    height: 24vh;
    position: relative;
    border-radius: 8px;
    margin-bottom: 0.5vh;
  }

  .addAnimalImageContainerBoxDelete:hover {
    transform: scale(1);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .addAnimal {
    flex-direction: column-reverse;
  }
  
  .addAnimalImageLoader {
    width: 100%;
    height: 70vh;
    margin-bottom: 10vh;
  }

  .addAnimalImageContainer {
    height: calc(70vh - 1vh - 50px);
  }

  .addAnimalFormContainer {
    width: 100%;
    padding-bottom: 5vh;
  }

  .addAnimalCollabItem {
    width: 45% !important;
  }

  .addAnimalCollabText {
    font-size: 3.8vw !important;
    margin-left: 3.5vw !important;
  }

  .animalsAdminAddFormButton {
    display: none;
  }

  .addAnimalInputLineTitle {
    font-size: 7vw;
  }

  .addAnimalFormTitle {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 7vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 1vh;
  }

  .animalsMobileSubmitButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 5vh;
  }
}