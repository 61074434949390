.animalsSidebarFilters {
  width: 100%;
  display: block;
  overflow-y: hidden;
  height: auto;
}

.animalsFiltersTitleLine, .animalsFiltersTitle {
  display: none;
}

.animalsFilter {
  width: 100% !important;
}

.animalsCheckbox {
  margin-top: 4vh !important;
  margin-bottom: 0 !important;
}

@media (orientation: portrait) and (max-width: 767px) {
  .animalsSidebarFilters {
    width: 100%;
    display: block;
    overflow-y: hidden;
    height: auto;
  }

  .animalsFiltersTitleLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .animalsFiltersTitle {
    display: flex;
    font-family: 'Avenir Next';
    font-weight: 800;
    font-size: 5vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 0vh;
  }

  .animalsFilter {
    display: flex !important;
  }
}