.adoptFormTitle {
  position: fixed;
  right: 5vw;
  top: 5vh;
  font-family: 'Avenir Next';
  font-weight: 800;
  color: #fff;
  font-size: 2vw;
  text-align: right;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.adoptFormProfile {
  position: absolute;
  left: 5vw;
  top: 10vh;
  font-family: 'Avenir Next';
  font-weight: 800;
  color: #333;
  font-size: 3vw;
  text-align: right;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.adoptFormContainer {
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10vh;
}

.adoptFormDate {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adoptFormDateLabel {
  font-family: 'Avenir Next';
  font-size: 1vw;
  font-weight: 400;
  color: #fff;
  margin-top: 2vh;
  margin-bottom: 2vh;
  align-self: flex-start;
}

.phoneLabel {
  align-self: flex-start;
}

.adoptFormDay {
  height: auto;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.adoptFormDayLabel {
  font-family: 'Avenir Next';
  font-size: 1vw;
  font-weight: 400;
  color: #fff;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.adoptFormDayInput {
  color: #fff;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-top: 0;
  margin-bottom: 1vh;
  height: 2vw;
  width: 100%;
  padding-left: 0;
}

.adoptFormDayInput::placeholder {
  color: rgba(255, 255, 255, 1);
}

.adoptFormDayInput:focus {
  outline: none;
}

.adoptFormDateSeparator {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1.5vw;
  color: #fff;
}

.formSeparator {
  height: 4vh;
}

.compulsoryDataWarning {
  font-family: 'Avenir Next';
  font-weight: 700;
  font-size: 0.9vw;
  color: #fff;
  margin-bottom: 2vh;
  align-self: flex-start;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .adoptFormTitle, .adoptFormProfile {
    display: none;
  }
  
  .adoptFormContainer {
    width: 70%;
    padding-bottom: 10vh;
  }
  
  .adoptFormDateLabel {
    font-size: 2vw;
  }
  
  .adoptFormDay {
    width: 25%;
  }
  
  .adoptFormDayLabel {
    font-size: 2vw;
  }
  
  .adoptFormDayInput {
    font-size: 2.3vw;
    line-height: 2.3vw;
    margin-bottom: 1vh;
    height: 4vw;
  }
  
  .adoptFormDateSeparator {
    font-family: 'Avenir Next';
    font-weight: 400;
    font-size: 2.5vw;
    color: #fff;
  }
  
  .formSeparator {
    height: 4vh;
  }

  .compulsoryDataWarning {
    font-size: 1.4vw;
    margin-bottom: 2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .adoptFormContainer {
    width: 90%;
    padding-bottom: 20vh;
  }
  
  .adoptFormDateLabel {
    font-size: 3vw;
  }
  
  .adoptFormDay {
    width: 28%;
  }
  
  .adoptFormDayLabel {
    font-size: 2.8vw;
    margin-bottom: 3vh;
  }
  
  .adoptFormDayInput {
    font-size: 4vw;
    line-height: 4vw;
    margin-bottom: 1vh;
    height: 8vw;
  }
  
  .adoptFormDateSeparator {
    display: none;
  }
  
  .formSeparator {
    height: 4vh;
  }

  .compulsoryDataWarning {
    font-size: 3vw;
    margin-bottom: 2vh;
  }
}