.helpPage {
  padding-top: 15vh !important;
  padding-bottom: 30vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .helpPage {
    padding-top: 8vh !important;
    padding-bottom: 15vh;
  }
}