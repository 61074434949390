.favoritesTitle {
  width: 90%;
  height: auto;
  margin-top: 4vh;
  margin-bottom: 3vh;
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 2.5vw;
  color: #333;
  text-align: left;
}

.favoritesMainContainer {
  margin-top: 0 !important;
}

.favoritesContainer {
  width: 100% !important;
}

.favoritesAnimalFileContainer {
  width: 23% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .favoritesTitle {
    font-size: 4vw;
  }
  
  .favoritesMainContainer {
    margin-top: 1vh !important;
  }
  
  .favoritesContainer {
    width: 100% !important;
  }
  
  .favoritesAnimalFileContainer {
    width: 30% !important;
    margin-left: 1.5% !important;
    margin-right: 1.5% !important;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .favoritesTitle {
    font-size: 7vw;
  }

  .favoritesAnimalFileContainer {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}