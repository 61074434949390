.zoneExtraInputContainer {
  margin-top: 2vh;
  width: 100%;
}

.collabItemsLabel {
  font-family: 'Avenir Next';
  font-weight: 500;
  color: #fff;
  font-size: 1vw;
  margin-bottom: 2vh;
  align-self: flex-start;
  margin-top: 3vh;
}

.collabItemsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collabItem {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.collabItemCheckbox {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  cursor: pointer;
}

.collabItemText {
  font-family: 'Avenir Next';
  font-weight: 500;
  color: #fff;
  font-size: 1.1vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 15px;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .collabItemsLabel {
    font-size: 2vw;
  }
  
  .collabItem {
    width: 25%;
  }
  
  .collabItemText {
    font-size: 2.2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .collabItemsLabel {
    font-size: 3vw;
  }
  
  .collabItem {
    width: auto;
  }
  
  .collabItemText {
    margin-left: 8px;
    font-size: 3vw;
  }
}