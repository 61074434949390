.formSelectContainer {
  margin-top: 3vh;
}

.formSelectLabel {
  font-family: 'Avenir Next';
  font-size: 1vw;
  font-weight: 400;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.react-select-container {
  width: 100%;
}

.react-select__menu {
  background-color: #fff !important;
}

.react-select__single-value, .react-select__option {
  font-family: 'Avenir Next';
  font-size: 1.2vw;
  font-weight: 500;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .formSelectLabel {
    margin-bottom: 2vh;
    font-size: 2vw;
    line-height: 2vw;
  }

  .react-select__single-value, .react-select__option {
    font-family: 'Avenir Next';
    font-size: 2.5vw;
    font-weight: 500;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .formSelectLabel {
    margin-bottom: 2vh;
    font-size: 3vw;
    line-height: 3vw;
  }

  .react-select__single-value, .react-select__option {
    font-family: 'Avenir Next';
    font-size: 5vw;
    font-weight: 500;
  }
}