.homeContainer {
  width: 100vw;
  height: auto;
}

.homeHeaderContainer {
  width: 100vw;
  height: 89vh;
  position: relative;
  overflow-x: hidden;
}

.mainDog {
  height: 95vh;
  width: auto;
  position: absolute;
  top: 45%;
  left: 52%;
  transform: translate(-50%, -50%);
  z-index: 500;
  pointer-events: none;
}

.mainCat {
  height: 70vh;
  width: auto;
  position: absolute;
  top: 45%;
  left: 52%;
  transform: translate(-50%, -50%);
  z-index: 500;
  pointer-events: none;
}

.switcherContainer {
  position: absolute;
  right: 0;
  bottom: 8vh;
  width: 10vw;
  height: 8vh;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07)
  ;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.switcherCat {
  width: 60%;
  height: auto;
  position: absolute;
  left: 1vw;
  bottom: 0.5vh;
}

.switcherDog {
  width: 65%;
  height: auto;
  position: absolute;
  left: 0vw;
  bottom: -1vh;
}

.switcherContainer:hover {
  width: 12vw;
  height: 9vh;
}

.switcherArrow {
  margin-right: 1vw;
  margin-left: 1vw;
  transition: all 0.5s ease-out;
}

.switcherContainer:hover .switcherArrow {
  margin-right: 1.5vw;
  margin-left: 1.5vw;
}

.firstTitle {
  font-family: 'Avenir Next';
  font-weight: 200;
  font-size: 7.5vw;
  position: absolute;
  top: 35%;
  right: 53%;
  transform: translate(0%, -50%);
  margin: 0;
  z-index: 400;
  letter-spacing: -0.2vw;
}

.secondTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 7.5vw;
  position: absolute;
  top: 45%;
  left: 62%;
  transform: translate(-5%, 5%);
  margin: 0;
  z-index: 400;
}

.buttonsContainer {
  position: absolute;
  top: 60%;
  left: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}

.buttonSeparator {
  height: 15px;
}

.homeBodyContainer {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #FAFCFE;
  overflow: hidden;
}

.socialContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socialSubheading {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
  width: 100%;
  text-align: center;
}

.socialHeading {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 4.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 10vh;
}

.socialBlocks {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
}

.socialBlock {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.socialMain {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 4.2vw;
  color: #DE692C;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.socialSecond {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.homeSheltersContainer {
  padding-bottom: 23vh;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homeSheltersInfo {
  margin-left: 5%;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.homeSheltersSub {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.3vw;
  color: #DE692C;
  margin-top: 0;
  margin-bottom: 1vh;
}

.homeSheltersTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 2.4vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.homeSheltersText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2.4vw;
  width: 100%;
  text-align: justify;
  color: #333;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 5vh;
}

.homeSheltersLinkContainer {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.homeSheltersLink {
  font-size: 1.3vw !important;
  color: #333 !important;
  font-weight: 500 !important;
  margin-right: 0 !important;
}

.homeSheltersImg {
  height: 100vh;
  width: 40vw;
  object-fit: cover;
}

.homeCTA {
  width: 100vw;
  height: 45vh;
  background-color: #DE692C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeCTAText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.8vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 6vh;
}

.helpContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 23vh;
  padding-bottom: 23vh;
}

.helpHeading {
  margin-bottom: 3vh;
  color: #DE692C;
}

.helpText {
  margin-bottom: 7vh;
  width: 80%;
  text-align: center;
  font-size: 1vw;
  line-height: 2.5vw;
}

.helpBlocks {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 7vh;
}

.helpRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

.helpBlock {
  width: 47%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.helpImg {
  height: auto;
  width: 25%;
  object-fit: cover;
}

.helpBlockInfo {
  flex: 1;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.helpBlockTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1.3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.helpBlockText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 2.2vw;
  color: rgba(51,51,51,0.8);
  margin-top: 0;
  margin-bottom: 0;
}

.helpButtons {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.helpButtonsSeparator {
  width: 30px;
}

.contactContainer {
  width: 80%;
  height: auto;
  margin-bottom: 23vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.contactInfoContainer {
  width: 33%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contactText {
  text-align: left;
}

.contactFormInner {
  width: 58%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formLine {
  width: 100%;
  height: auto;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formInputOuterContainer {
  width: 48%;
  height: auto;
}

.formInputFullContainer {
  width: 100%;
  height: auto;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .firstTitle, .secondTitle {
    display: none;
  }

  .mainDog {
    height: 70vh;
    top: 35%;
    left: 52%;
  }
  
  .mainCat {
    height: 50vh;
    top: 35%;
    left: 50%;
  }

  .switcherContainer {
    position: absolute;
    right: 0;
    bottom: 48vh;
    width: 20vw;
    height: 5vh;
  }
  
  .switcherCat {
    width: 70%;
    left: auto;
    right: 3vw;
  }
  
  .switcherDog {
    width: 65%;
    left: auto;
    right: 4vw;
  }
  
  .switcherContainer:hover {
    width: 20vw;
    height: 5vh;
  }
  
  .switcherArrow {
    display: none;
  }

  .buttonsContainer {
    position: absolute;
    top: 77%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .buttonSeparator {
    height: 0;
    width: 3vw;
  }

  .socialContainer {
    height: auto;
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  
  .socialSubheading {
    font-size: 2.3vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .socialHeading {
    font-size: 6vw;
    margin-bottom: 7vh;
  }
  
  .socialBlocks {
    width: 85%;
    margin-bottom: 7vh;
  }
  
  .socialMain {
    font-size: 5.5vw;
    margin-bottom: 0.5vh;
  }
  
  .socialSecond {
    font-size: 2.5vw;
  }

  .homeSheltersContainer {
    padding-bottom: 23vh;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .homeSheltersSub {
    font-size: 2.2vw;
  }
  
  .homeSheltersTitle {
    font-size: 4.5vw;
  }
  
  .homeSheltersText {
    font-size: 1.5vw;
    line-height: 3.4vw;
    margin-bottom: 2vh;
  }
  
  .homeSheltersLink {
    font-size: 2.2vw !important;
  }
  
  .homeSheltersImg {
    height: 70vh;
    width: 40vw;
    object-fit: cover;
  }

  .homeCTA {
    width: 100vw;
    height: 30vh;
  }
  
  .homeCTAText {
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 3vw;
    color: #fff;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .helpContainer {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  
  .helpHeading {
    margin-top: -1vh;
    margin-bottom: 3vh;
  }
  
  .helpText {
    width: 90%;
    font-size: 2vw;
    line-height: 4vw;
    margin-bottom: 8vh;
  }

  .helpBlocks {
    margin-bottom: 3vh;
  }
  
  .helpBlock {
    width: 47%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .helpBlockTitle {
    font-size: 2vw;
    margin-bottom: 0.5vh;
  }
  
  .helpBlockText {
    font-size: 1.7vw;
    line-height: 3.4vw;
    color: rgba(51,51,51,0.8);
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .helpButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .helpButtonsSeparator {
    width: 15px;
  }

  .contactContainer {
    width: 85%;
    margin-bottom: 15vh;
  }
  
  .contactInfoContainer {
    width: 33%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .contactText {
    text-align: left;
    margin-top: -2vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .mainDog {
    max-width: 100vw;
    object-fit: cover;
  }

  .mainCat {
    max-width: 100vw;
    object-fit: cover;
  }
  
  .socialSubheading {
    font-size: 3.5vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 2vh;
  }
  
  .socialHeading {
    font-size: 6.6vw;
    margin-bottom: 6vh;
  }

  .socialBlocks {
    width: 85%;
    margin-bottom: 6vh;
  }
  
  .socialMain {
    font-size: 6.5vw;
    margin-bottom: 0.3vh;
  }
  
  .socialSecond {
    font-size: 2.5vw;
  }

  .homeSheltersContainer {
    padding-bottom: 23vh;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  
  .homeSheltersInfo {
    margin-left: 0;
    width: 90%;
    height: auto;
  }
  
  .homeSheltersSub {
    font-size: 4vw;
  }
  
  .homeSheltersTitle {
    font-size: 8vw;
  }
  
  .homeSheltersText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }
  
  .homeSheltersLink {
    font-size: 4.5vw !important;
  }
  
  .homeSheltersImg {
    height: 50vh;
    width: 90vw;
    object-fit: cover;
    margin-bottom: 5vh;
  }

  .homeCTA {
    width: 100vw;
    height: 35vh;
  }
  
  .homeCTAText {
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 4.5vw;
    line-height: 7vw;
    width: 90%;
    text-align: center;
    color: #fff;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  .helpHeading {
    margin-top: -1vh;
    margin-bottom: 3vh;
    font-size: 7.5vw;
  }
  
  .helpText {
    width: 90%;
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 8vh;
  }

  .helpBlocks {
    margin-bottom: 5vh;
    width: 85%;
  }

  .helpRow {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }
  
  .helpBlock {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 9vh;
  }

  .helpImg {
    height: auto;
    width: 35%;
    object-fit: cover;
  }

  .helpBlockInfo {
    margin-left: 5vw;
  }
  
  .helpBlockTitle {
    font-size: 4vw;
    margin-bottom: 1vh;
  }
  
  .helpBlockText {
    font-size: 3.4vw;
    line-height: 6.8vw;
  }
  
  .helpButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .helpButtonsSeparator {
    width: 15px;
  }

  .contactContainer {
    width: 80%;
    margin-bottom: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .contactInfoContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .contactText {
    text-align: left;
    margin-top: -2vh;
    font-size: 4vw;
    margin-bottom: 5vh;
  }
  
  .contactFormInner {
    width: 100%;
    height: auto;
  }
  
  .formInputOuterContainer {
    width: 48%;
    height: auto;
  }
  
  .formInputFullContainer {
    width: 100%;
    height: auto;
    margin-bottom: 2vh;
  }
}