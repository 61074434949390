.navbar {
  width: 90%;
  height: 9vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
}

.navbar a {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: auto;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.mainNav {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.link {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1vw;
  color: #DE692C;
  margin-right: 5vw;
  outline: none;
  position: relative;
  cursor: pointer;
}

.authBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: auto;
}

.link:after{
  content: '';
  position: absolute;
  width: 0; height: 1px;
  display: block;
  margin-top: -1px;
  right: 0;
  background: #DE692C;
  transition: width .5s ease;
  -webkit-transition: width .5s ease;
}
 
.link:hover:after{
  width: 100%;
  left: 0;
  background: #DE692C;
}

.mobileMenuButton, .mobileMenu, .mobileLink {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {
  .navbar {
    height: 7vh;
  }

  .mainNav {
    display: none;
  }

  .logo {
    height: 100%;
  }

  .mobileMenuButton {
    display: flex;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 10000000000000;
    padding-top: 8vh;
  }

  .mobileLink {
    display: flex;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 3.5vw;
    color: #fff;
    margin-bottom: 6vh;
    margin-top: 0;
  }

  .mobileMenuClose {
    display: flex;
    position: absolute;
    top: 3vh;
    right: 5vw;
    z-index: 1000000;
  }

  .authBtnsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .mobileLinkSpecial {
    color: #DE692C;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .navbar {
    height: 7vh;
  }

  .mainNav {
    display: none;
  }

  .logo {
    height: 100%;
  }

  .mobileMenuButton {
    display: flex;
  }

  .mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 10000000000000;
    padding-top: 8vh;
  }

  .mobileLink {
    display: flex;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 7vw;
    color: #fff;
    margin-bottom: 5vh;
    margin-top: 0;
  }

  .mobileMenuClose {
    display: flex;
    position: absolute;
    top: 3vh;
    right: 5vw;
    z-index: 100000;
  }

  .authBtnsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .mobileLinkSpecial {
    color: #DE692C;
  }
}