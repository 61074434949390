.formInputSimpleContainer {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.formInputSimpleLabel {
  margin-bottom: 1vh;
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 1vw;
  color: #333;
}

.formInputSimple {
  color: #333;
  background-color: transparent;
  border-bottom: 1px solid rgba(51, 51, 51, 0.7);
  border-right: none;
  border-top: none;
  border-left: none;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-top: 0;
  margin-bottom: 1vh;
  height: 2vw;
  width: 100%;
  padding-left: 0;
}

.formInputSimple::placeholder {
  color: rgba(51, 51, 51, 1);
}

.formInputSimple:focus {
  outline: none;
}

@media (orientation: portrait) and (max-width: 1024px) and (min-width: 768px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 2vw;
    line-height: 2vw;
  }
  
  .formInputSimple {
    font-size: 2.3vw;
    line-height: 2.3vw;
    height: 4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .formInputSimpleLabel {
    margin-bottom: 1vh;
    font-size: 3vw;
    line-height: 3vw;
  }
  
  .formInputSimple {
    font-size: 4vw;
    line-height: 4vw;
    height: 8vw;
  }
}