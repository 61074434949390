.formButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  color: #fff;
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1.2vw;
  border: none;
  margin-top: 1vh;
  cursor: pointer;
  background-color: #DE692C;
  padding-right: 3.5vw;
  padding-left: 3.5vw;
  height: 5vh;
  border-radius: 7px;
  align-self: flex-end;
  transition: all 0.3s ease-out;
}

.formButton:hover {
  transform: scale(0.9);
}

.formButton:focus {
  outline: none;
}

.formButton:disabled {
  cursor: default;
  opacity: 0.6;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .formButton {
    padding-right: 6vw;
    padding-left: 6vw;
    height: 5vh;
    font-size: 2.5vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .formButton {
    padding-right: 12vw;
    padding-left: 12vw;
    height: 6vh;
    font-size: 5vw;
    margin-right: 0;
  }
}