.adoptantsTableHeaderColumn {
  width: 29% !important;
}

.adoptantsTableHeaderColumnBad {
  width: 13% !important;
}

@media (orientation: portrait) and (max-width: 767px) {
  .adoptantsTableHeaderColumn {
    width: 70% !important;
  }
  
  .adoptantsTableHeaderColumnBad {
    width: 30% !important;
  }
}