.aboutContainer {
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10vh;
}

.aboutFirstContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
}

.aboutTitle {
  font-size: 3.5vw !important;
  margin-bottom: 8vh !important;
}

.aboutFirstInfo {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutImg {
  width: 40%;
  height: auto;
}

.aboutSecondContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutSecondInfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15vh;
}

.aboutInfoFirstColumn {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutInfoSecondColumn {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutInfoBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1vh;
}

.aboutInfoBlockTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1.5vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
}

.aboutInfoBlockTitleContrast {
  color: #DE692C;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .aboutContainer {
    width: 100vw;
    margin-top: 7vh;
  }
  
  .aboutFirstContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10vh;
  }
  
  .aboutFirstInfo {
    margin-left: 5%;
    width: 55%;
  }
  
  .aboutImg {
    width: 35%;
    height: 60vh;
    object-fit: cover;
    object-position: 20% 0%;
  }
  
  .aboutSecondContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .aboutSecondInfo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15vh;
  }

  .aboutTitle {
    font-size: 5vw !important;
    margin-bottom: 5vh !important;
  }
  
  .aboutInfoBlockTitle {
    font-size: 3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .aboutContainer {
    width: 90vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10vh;
  }
  
  .aboutFirstContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
  }
  
  .aboutFirstInfo {
    margin-left: 0;
    width: 100%;
  }
  
  .aboutImg {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    object-position: 0% 90%;
    margin-bottom: 5vh;
  }
  
  .aboutSecondContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .aboutSecondInfo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15vh;
  }

  .aboutInfoFirstColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .aboutInfoSecondColumn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .aboutTitle {
    font-size: 8vw !important;
    margin-bottom: 4vh !important;
  }
  
  .aboutInfoBlockTitle {
    font-size: 5vw;
  }
}