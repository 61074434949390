.completeContainer {
  width: 100vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.completeText {
  font-family: 'Avenir Next';
  font-weight: 500;
  color: #333;
  font-size: 1.2vw;
  width: auto;
  text-align: center;
  margin-bottom: 7vh;
  margin-top: 7vh;
}

.completeForm {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000010000;
  background-color: #000;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.adoptForm {
  padding-top: 18vh;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.completeBackButton {
  position: fixed;
  left: 5vw;
  top: 5vh;
  cursor: pointer;
}

.completeSignUpAltText {
  width: 80%;
  font-size: 1.3vw;
  line-height: 2.6vw;
  margin-bottom: 5vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .completeText {
    font-size: 2.3vw;
    width: 85vw;
    margin-bottom: 3vh;
  }

  .completeSignUpAltText {
    font-size: 2.3vw;
    line-height: 4.6vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .completeText {
    font-size: 3.8vw;
    width: 90vw;
    text-align: center;
    margin-bottom: 3vh;
    margin-top: 3vh;
  }

  .completeSignUpAltText {
    font-size: 4vw;
    line-height: 8vw;
  }
}