.animalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000000;
  pointer-events: all;
  background-color: rgba(0,0,0,0.3);
  padding: 20% 25% 20% 25%;
}

.animalOverlayClickable {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

.animalOverlayFixed {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 80%;
  z-index: 100000;
}

.animalOverlayInner {
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02),
    0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03),
    0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05);
}

.animalOverlayContent {
  width: 92%;
  height: 88%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.animalOverlayButtonsContainer {
  position: absolute;
  top: -3%;
  right: -1%;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.animalOverlayButton {
  cursor: pointer;
  transition: transform 0.3s ease-out;
  padding: 5px;
  border-radius: 8px;
}

.animalOverlayButton:hover {
  transform: scale(0.9);
}

.animalOverlayButtonSpin:hover {
  transform: rotate(90deg) !important;
}

.animalOverlayMain {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.animalOverlayImageContainer {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.animalOverlayImage {
  width: 100%;
  height: 73%;
  object-fit: cover;
  border-radius: 8px;
}

.animalOverlayImageButtonsContainer {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.animalOverlayImageButton {
  padding: 3px;
  border-radius: 20px;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.animalOverlayImageButton:hover {
  opacity: 0.7;
}

.animalOverlayImageSeparator {
  width: 5px;
}

.animalOverlayImageOutgoingButton {
  text-decoration: none;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 8px;
  border-radius: 20px;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
}

.animalOverlayImageOutgoingButton:hover {
  opacity: 0.7;
}

.animalOverlayAdoptButton {
  width: 80%;
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #DE692C;
  border-radius: 8px;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  transition: transform 0.3s ease-out;
  cursor: pointer;
}

.animalOverlayAdoptButton:focus {
  outline: none;
}

.animalOverlayAdoptButton:disabled {
  opacity: 0.5;
}

.animalOverlayAdoptButton:hover {
  transform: scale(0.9);
}

.animalOverlayMainInfo {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.animalOverlayMainInfoTitle {
  width: 100%;
  height: auto;
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
}

.animalOverlayMainInfoLine {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}

.animalOverlayMainInfoStrong {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.animalOverlayMainInfoText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.animalOverlayAdditionalInfo {
  width: 100%;
  height: 28%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
}

.animalOverlayAdditionalInfoTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
}

.animalOverlayAdditionalInfoText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  text-align: justify;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.animalOverlayAdoptButtonMobile {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .animalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100000000;
    pointer-events: all;
    background-color: rgba(0,0,0,0.3);
    padding: 20% 5% 20% 5%;
  }
  
  .animalOverlayFixed {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 60%;
    z-index: 10000000;
  }
  
  .animalOverlayMain {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .animalOverlayImageContainer {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .animalOverlayImage {
    height: 73%;
  }
  
  .animalOverlayImageSeparator {
    width: 7px;
  }
  
  .animalOverlayAdoptButton {
    width: 90%;
    height: 16%;
    font-size: 2vw;
  }
  
  .animalOverlayAdoptButton:hover {
    transform: scale(1);
  }
  
  .animalOverlayMainInfoTitle {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
  
  .animalOverlayMainInfoLine {
    margin-bottom: 1.5vh;
  }
  
  .animalOverlayMainInfoStrong {
    font-size: 2vw;
  }
  
  .animalOverlayMainInfoText {
    font-size: 2vw;
  }
  
  .animalOverlayAdditionalInfo {
    width: 100%;
    height: 28%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .animalOverlayAdditionalInfoTitle {
    font-family: 'Avenir Next';
    font-weight: 800;
    font-size: 2vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 1vh;
  }
  
  .animalOverlayAdditionalInfoText {
    font-family: 'Avenir Next';
    font-weight: 400;
    font-size: 2vw;
    line-height: 4vw;
    text-align: justify;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
    overflow-y: scroll;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .animalOverlay {
    padding: 5% 5% 5% 5%;
  }
  
  .animalOverlayFixed {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    z-index: 10000000;
  }

  .animalOverlayInner {
    display: block;
    overflow-y: scroll;
  }

  .animalOverlayContent {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: block;
    margin-top: 20%;
  }

  .animalOverlayButtonsContainer {
    top: -5%;
    right: -1%;
    z-index: 1000000000;
  }
  
  .animalOverlayButton:hover {
    transform: scale(1);
  }
  
  .animalOverlayButtonSpin:hover {
    transform: rotate(0deg) !important;
  }
  
  .animalOverlayMain {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .animalOverlayImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 5vh;
  }
  
  .animalOverlayImage {
    height: 35vh;
  }
  
  .animalOverlayImageSeparator {
    width: 7px;
  }

  .animalOverlayImageButtonsContainer {
    width: auto;
    right: auto;
    top: 5px;
    left: 10px;
  }
  
  .animalOverlayImageButton {
    padding: 5px;
  }
  
  .animalOverlayImageButton:hover {
    opacity: 1;
  }
  
  .animalOverlayImageOutgoingButton {
    top: auto;
    left: auto;
    right: 5px;
    bottom: 5px;
    padding: 10px;
  }

  .animalOverlayImageButton:hover {
    opacity: 1;
  }

  .animalOverlayAdoptButton {
    display: none;
  }
  
  .animalOverlayAdoptButton:hover {
    transform: scale(1);
  }

  .animalOverlayMainInfo {
    width: 100%;
  }
  
  .animalOverlayMainInfoTitle {
    font-size: 9vw;
    margin-bottom: 3vh;
  }
  
  .animalOverlayMainInfoLine {
    margin-bottom: 2vh;
  }
  
  .animalOverlayMainInfoStrong {
    font-size: 3.5vw;
  }
  
  .animalOverlayMainInfoText {
    font-size: 3.5vw;
  }

  .animalOverlayAdditionalInfo {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .animalOverlayAdditionalInfoTitle {
    font-size: 3.5vw;
    margin-bottom: 2vh;
  }
  
  .animalOverlayAdditionalInfoText {
    font-size: 3.5vw;
    line-height: 7vw;
    margin-bottom: 10vh;
    overflow-y: auto;
  }

  .animalOverlayAdoptButtonMobile {
    display: flex;
    width: 90%;
    height: 7vh;
    margin-bottom: 5vh;
    font-size: 4vw;
    margin-left: auto;
    margin-right: auto;
  }
}