.overlayButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: auto;
  z-index: 100000000;
}

.overlayButtonProfilePic {
  height: 5vh;
  width: 5vh;
  object-fit: cover;
  border-radius: 5px;
  z-index: 100000000;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.overlayButtonText {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1vw;
  z-index: 1000000000;
}

.overlayMenu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  padding-top: 15vh;
}

.trails-text {
  position: relative;
  width: 100%;
  right: 5vw;
  text-align: right;
  height: 40px;
  line-height: 40px;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.3vw;
  will-change: transform, opacity;
  overflow: hidden;
  transition: right 0.3s ease-out;
  cursor: pointer;
}

.trails-text:hover {
  right: 5.5vw;
}

.trails-text > div {
  overflow: hidden;
}

.overlayCloseButtonMobile {
  display: none;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .overlayButtonContainer {
    margin-top: 5vh;
    flex-direction: row-reverse;
  }
  
  .overlayButtonText {
    font-size: 3.5vw;
    margin-left: 3vw;
    margin-right: 0;
    z-index: 1000000000;
  }
  
  .overlayMenu {
    z-index: 11000000000;
  }
  
  .trails-text {
    height: 40px;
    line-height: 40px;
    font-size: 3.5vw;
    margin-bottom: 2vh;
  }
  
  .trails-text:hover {
    right: 5vw;
  }

  .overlayCloseButtonMobile {
    display: flex;
    position: absolute;
    top: 3vh;
    right: 5vw;
    z-index: 1000000;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .overlayButtonContainer {
    margin-top: 7vh;
    flex-direction: row-reverse;
  }

  .overlayButtonProfilePic {
    height: 13vw;
    width: 13vw;
  }
  
  .overlayButtonText {
    font-size: 5vw;
    margin-left: 4vw;
    margin-right: 0;
    z-index: 1000000000;
  }
  
  .overlayMenu {
    z-index: 11000000000;
  }
  
  .trails-text {
    height: 40px;
    line-height: 40px;
    font-size: 5.5vw;
    margin-bottom: 2vh;
  }
  
  .trails-text:hover {
    right: 5vw;
  }
}