.loginPageContainer {
  width: 100%;
  height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPageImage {
  width: 15vw;
  height: auto;
  object-fit: cover;
  margin-bottom: 5vh;
}

.loginPageText {
  width: 55%;
  text-align: center;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 5vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .loginPageImage {
    width: 30vw;
    height: auto;
    object-fit: cover;
    margin-bottom: 5vh;
  }
  
  .loginPageText {
    width: 70%;
    text-align: center;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 3vw;
    line-height: 6vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 5vh;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .loginPageImage {
    width: 50vw;
    height: auto;
    object-fit: cover;
    margin-bottom: 3vh;
  }
  
  .loginPageText {
    width: 80%;
    text-align: center;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 4.5vw;
    line-height: 9vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 3vh;
  }
}