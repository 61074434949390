.emptyAnimatedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}

.emptyAnimatedButton:focus {
  outline: none;
}

.emptyAnimatedButton:hover {
  transform: scale(0.9);
}

.emptyButtonText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1vw;
  color: #DE692C;
  z-index: 1;
  margin: 0;
  transition: all 0.5s ease-out;
}

@media (orientation: portrait) and (max-width: 1024px) {

  .emptyAnimatedButton {
    margin-bottom: 4vh;
  }

  .emptyButtonText {
    font-size: 4vw;
  }
}