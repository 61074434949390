.singleShelterSidebarMessage {
  width: 25%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.singleShelterMainMessage {
  width: 75%;
}

.singleShelterSidebar {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.singleShelterSidebarTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1.8vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 3vh;
}

.singleShelterSidebarLabel {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 1vh;
}

.singleShelterSidebarText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2.5vh;
  word-wrap: break-word;
  width: 100%;
}

.singleShelterLoginContainer {
  width: 75%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.singleShelterLoginText {
  width: 70%;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.4vw;
  line-height: 1.8vw;
  color: #333;
  text-align: center;
  margin-top: 0;
  margin-bottom: 8vh;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .singleShelterSidebarMessage {
    font-size: 2.5vw;
    line-height: 5vw;
  }
  
  .singleShelterMainMessage {
    width: 75%;
    font-size: 2.5vw;
  }
  
  .singleShelterSidebar {
    width: 25%;
  }
  
  .singleShelterSidebarTitle {
    font-size: 2.4vw;
  }
  
  .singleShelterSidebarLabel {
    font-size: 1.5vw;
  }
  
  .singleShelterSidebarText {
    font-size: 1.5vw;
    margin-bottom: 3.5vh;
  }
  
  .singleShelterLoginText {
    font-size: 2.4vw;
    line-height: 4.5vw;
    margin-bottom: 4vh;
  }

  .singleShelterAnimalFileContainer {
    width: 45% !important;
    margin-left: 2.5% !important;
    margin-right: 2.5% !important;
  }

  .singleShelterAnimalFileContainer:hover {
    transform: scale(1) !important;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .singleShelterSidebarMessage {
    font-size: 4.5vw;
    line-height: 9vw;
    width: 100%;
  }
  
  .singleShelterMainMessage {
    width: 100%;
    font-size: 4.5vw;
    line-height: 9vw;
  }
  
  .singleShelterSidebar {
    width: 100%;
    margin-bottom: 5vh;
  }
  
  .singleShelterSidebarTitle {
    font-size: 6vw;
  }
  
  .singleShelterSidebarLabel {
    font-size: 3.5vw;
  }
  
  .singleShelterSidebarText {
    font-size: 4vw;
    line-height: 8vw;
    margin-bottom: 3vh;
  }

  .singleShelterLoginContainer {
    width: 100%;
  }
  
  .singleShelterLoginText {
    width: 100%;
    font-size: 4vw;
    line-height: 7vw;
    margin-bottom: 4vh;
  }

  .singleShelterAnimalFileContainer {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}