.sheltersContainer {
  width: 90%;
  height: auto;
  min-height: 88vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5vh;
  margin-bottom: 10vh;
}

.sheltersSidebar {
  width: 25%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sheltersTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 2.7vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
}

.sheltersLocationFilter {
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sheltersAuxInfo {
  width: 60%;
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  text-align: justify;
  color: #333;
  margin-top: auto;
  margin-bottom: 0;
}

.sheltersLogoMessage {
  width: 100%;
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.5vw;
  text-align: center;
  color: #333;
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.sheltersLogosContainer {
  width: 75%;
  height: auto;
  position: relative;
}

.shelterLogo {
  display: inline-block;
  width: 30%;
  margin-right: 1.5%;
  margin-left: 1.5%;
  object-fit: cover;
  height: 28vh;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.shelterLogo:hover {
  transform: scale(0.9);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .sheltersSidebar {
    width: 30%;
  }
  
  .sheltersTitle {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
  
  .sheltersLocationFilter {
    width: 90%;
  }
  
  .sheltersAuxInfo {
    width: 90%;
    font-size: 2vw;
    line-height: 4vw;
  }
  
  .sheltersLogoMessage {
    font-size: 3vw;
    margin-top: 25vh;
    margin-bottom: 25vh;
  }
  
  .sheltersLogosContainer {
    width: 65%;
    height: auto;
    position: relative;
  }
  
  .shelterLogo {
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    height: 18vh;
  }
  
  .shelterLogo:hover {
    transform: scale(1);
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .sheltersContainer {
    width: 90%;
    height: auto;
    min-height: 88vh;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
  
  .sheltersSidebar {
    width: 100%;
    height: auto;
  }
  
  .sheltersTitle {
    font-family: 'Avenir Next';
    font-weight: 800;
    font-size: 8vw;
    color: #333;
    margin-top: 0;
    margin-bottom: 2.5vh;
  }
  
  .sheltersLocationFilter {
    display: none;
  }
  
  .sheltersAuxInfo {
    width: 100%;
    font-size: 4.2vw;
    line-height: 10vw;
    margin-top: 0;
    margin-bottom: 5vh;
  }
  
  .sheltersLogoMessage {
    width: 100%;
    font-family: 'Avenir Next';
    font-weight: 500;
    font-size: 4.5vw;
    text-align: center;
    color: #333;
    margin-top: 25vh;
    margin-bottom: 25vh;
  }
  
  .sheltersLogosContainer {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .shelterLogo {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 4vh;
    height: 28vh;
  }
  
  .shelterLogo:hover {
    transform: scale(1);
  }
}