.collabsTableContainer {
  width: 100%;
  height: auto;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.collabsTableHeader {
  width: 81%;
  padding-left: 4.5%;
  padding-right: 4.5%;
  height: 50px;
  margin-bottom: 2vh;
  background-color: #DE692C;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.collabsTableExpandable {
  width: 90%;
  margin-bottom: 2vh;
  display: block;
  overflow-y: hidden;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.collabsTableExtra {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.collabsTableLine {
  width: 90%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 1vh;
}

.collabsTableHeaderColumn {
  height: 100%;
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Avenir Next';
  font-weight: 800;
  color: #fff;
  font-size: 1vw;
}

.collabsTableHeaderColumnSmall {
  height: 100%;
  width: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.collabsTableHeaderColumnBad {
  width: 16% !important;
}

.collabsTableRotatingBad {
  border-radius: 10px;
  background-color: rgba(225, 225, 225, 0);
  transition: all 0.3s ease-out;
}

.collabsTableRotatingBad:hover {
  background-color: rgba(225, 225, 225, 0.5);
}

.collabsTableLineColumn {
  height: 100%;
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Avenir Next';
  font-weight: 500;
  color: #333;
  font-size: 1vw;
}

.collabsTableExtraLine {
  width: 90%;
  height: 50px;
  margin-bottom: 2vh;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.collabsTableExtraColumn {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.collabsTableExtraColumnTitle {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Avenir Next';
  font-weight: 800;
  color: #333;
  font-size: 1vw;
  margin-right: 1vw;
}

.collabsTableExtraColumnText {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Avenir Next';
  font-weight: 500;
  color: #333;
  font-size: 1vw;
}

.collabsTableShowMoreContainer {
  width: 90%;
  height: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.collabsTableShowMoreLink {
  font-size: 1.5vw !important;
  color: #333 !important;
}

.collabsTableFiltersContainer {
  width: 90%;
  height: 120px;
  display: block;
  overflow-y: hidden;
}

.collabsTableFiltersTitleLine {
  width: 100%;
  height: 50px;
  margin-top: 0;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collabsTableProvinceLine {
  width: 90%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
}

.collabsTableProvinceHalf {
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.collabsTableSwitchesContainer{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.collabsTableFiltersTitle {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.collabsTableFilterButton {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.1vw;
  color: #fff;
  background-color: #DE692C;
  border-radius: 8px;
  padding: 2vh 5vw 2vh 5vw;
  transform: scale(1);
  transition: transform 0.3s ease-out;
  cursor: pointer;
}

.collabsTableFilterButton:hover {
  transform: scale(0.9);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .collabsTableHeaderColumn {
    width: 26%;
    font-size: 1.5vw;
  }

  .collabsTableHeaderColumnBad {
    width: 7% !important;
  }
  
  .collabsTableRotatingBad {
    border-radius: 10px;
    background-color: rgba(225, 225, 225, 0);
    transition: all 0.3s ease-out;
  }
  
  .collabsTableRotatingBad:hover {
    transform: rotate(0);
    background-color: rgba(225, 225, 225, 0);
  }
  
  .collabsTableLineColumn {
    width: 26%;
    font-size: 1.3vw;
  }
  
  .collabsTableExtraLine {
    margin-bottom: 1vh;
  }
  
  .collabsTableExtraColumnTitle {
    font-size: 1.3vw;
    margin-right: 0.5vw;
  }
  
  .collabsTableExtraColumnText {
    font-size: 1.3vw;
  }
  
  .collabsTableShowMoreContainer {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .collabsTableShowMoreLink {
    font-size: 2.2vw !important;
  }
  
  .collabsTableFiltersContainer {
    margin-bottom: 0;
  }
  
  .collabsTableFiltersTitle {
    font-size: 3vw;
  }
  
  .collabsTableFilterButton {
    font-size: 2vw;
    padding: 1.3vh 3vw 1.3vh 3vw;
  }
  
  .collabsTableFilterButton:hover {
    transform: scale(1);
  }

  .collabsTableSwitchesContainer {
    justify-content: space-between;
  }

  .collabsTableCheckboxContainer {
    width: auto !important;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .collabsTableContainer {
    padding-top: 7vh;
    padding-bottom: 7vh;
  }
  
  .collabsTableHeader {
    width: 81%;
    padding-left: 4.5%;
    padding-right: 4.5%;
    height: 50px;
    margin-bottom: 2vh;
    background-color: #DE692C;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .collabsTableExpandable {
    width: 90%;
    margin-bottom: 2vh;
    display: block;
    overflow-y: hidden;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .collabsTableExtra {
    width: 100%;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .collabsTableLine {
    width: 90%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 0vh;
  }
  
  .collabsTableHeaderColumn {
    width: 49%;
    font-size: 3.5vw;
  }
  
  .collabsTableHeaderColumnSmall {
    width: 12%;
  }
  
  .collabsTableHeaderColumnBad {
    width: 15% !important;
  }
  
  .collabsTableLineColumn {
    width: 50%;
    font-size: 3.5vw;
  }
  
  .collabsTableExtraLine {
    height: auto;
    margin-bottom: 0;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .collabsTableExtraColumn {
    margin-bottom: 0;
    width: 100%;
  }
  
  .collabsTableExtraColumnTitle {
    font-size: 3.5vw;
    margin-right: 2.5vw;
  }
  
  .collabsTableExtraColumnText {
    font-size: 3.5vw;
  }
  
  .collabsTableShowMoreContainer {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  
  .collabsTableShowMoreLink {
    font-size: 5.2vw !important;
  }
  
  .collabsTableFiltersContainer {
    width: 90%;
    display: block;
    overflow-y: hidden;
    margin-bottom: 0vh;
  }
  
  .collabsTableFiltersTitleLine {
    width: 100%;
    height: 50px;
    margin-top: 0;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .collabsTableSwitchesContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .collabsTableProvinceLine {
    width: 90%;
    height: auto;
    flex-direction: column;
    margin-bottom: 5vh;
  }
  
  .collabsTableProvinceHalf {
    width: 100%;
    margin-bottom: 0;
  }

  .collabsTableCheckboxContainer {
    width: 100% !important;
    margin-bottom: 2vh !important;
  }
  
  .collabsTableFiltersTitle {
    font-size: 6vw;
  }
  
  .collabsTableFilterButton {
    font-size: 5vw;
    margin-top: 2vh;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    transition: all 0.3s ease-out;
    opacity: 1;
  }
  
  .collabsTableFilterButton:hover {
    transform: scale(1);
    opacity: 0.8;
  }

  .collabsTableCheckbox {
    width: 35px !important;
    height: 35px !important;
  }

  .collabsTableCheckboxText {
    font-size: 5vw !important;
  }
}