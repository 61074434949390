.animalsAdmin {
  width: 100%;
  height: auto;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
}

.animalsFilesContainer {
  width: 60%;
  margin-top: 3vh;
  height: auto;
  margin-right: 5%;
  margin-bottom: 13vh;
}

.animalsAdminAddButtonLine {
  width: 100%;
  height: 50px;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.animalsAdminAddButton {
  height: 35px;
  width: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 2.3vw;
  padding-right: 2.3vw;
  background-color: #DE692C;
  transition: transform 0.3s ease-out;
  cursor: pointer;
}

.animalsAdminAddButton:hover {
  transform: scale(0.9);
}

.animalsAdminAddButtonText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1vw;
  color: #fff;
  margin-left: 0.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.animalFileContainer {
  display: inline-block;
  position: relative;
  width: 30%;
  margin-left: 3%;
  height: 30vh;
  margin-bottom: 5vh;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.014),
    0 6.7px 5.3px rgba(0, 0, 0, 0.02),
    0 12.5px 10px rgba(0, 0, 0, 0.025),
    0 22.3px 17.9px rgba(0, 0, 0, 0.03),
    0 41.8px 33.4px rgba(0, 0, 0, 0.036),
    0 100px 80px rgba(0, 0, 0, 0.05);
}

.animalFileContainerOverlay {
  height: 70%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(51,51,51,0.8);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.animalFileContainerOverlayButton {
  width: 60%;
  height: 35px;
  border: 1px solid #DE692C;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  opacity: 0;
  cursor: pointer;
  will-change: opacity,border;
}

.animalFileContainerOverlayButtonText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1vw;
  color: #DE692C;
  margin-left: 1vw;
  margin-top: 0;
  margin-bottom: 0;
}

.animalFileContainerOverlaySeparator {
  height: 2vh;
}

.animalFileImage {
  height: 70%;
  width: 100%;
  object-fit: cover;
}

.animalFileInfo {
  height: 30%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.animalFileName {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.3vw;
  color: #333;
  margin-left: 8%;
  margin-top: 0;
  margin-bottom: 0;
}

.animalFileAge {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1.1vw;
  color: rgba(51, 51, 51, 0.8);
  margin-right: 8%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: right;
}

.animalFileContainer:hover {
  transform: scale(0.95);
}

.animalFileContainer:hover .animalFileContainerOverlay {
  opacity: 1;
}

.animalFileContainer:hover .animalFileContainerOverlayButton {
  opacity: 1;
}

.animalFileContainerOverlayButton:hover {
  border: 2px solid #DE692C;
}

.animalsAdminMainInfoContainer {
  width: 30%;
  height: 80vh;
  position: absolute;
  top: 3vh;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  pointer-events: none;
  z-index: 100;
}

.animalsAdminMainInfoTitle {
  width: 80%;
  height: auto;
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 2vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.animalsAdminMainInfoLine {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.animalsAdminMainInfoLineText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1.1vw;
  color: rgba(51,51,51,0.8);
  margin-top: 0;
  margin-bottom: 0;
}

.animalsAdminMainInfoLineStrong {
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

.animalsAdminMainInfoAdditional {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.animalsAdminMainInfoAdditionalTitle {
  width: 100%;
  height: auto;
  font-family: 'Avenir Next';
  font-weight: 800;
  font-size: 1.3vw;
  color: #333;
  margin-top: 0;
  margin-bottom: 2vh;
}

.animalsAdminMainInfoAdditionalText {
  width: 100%;
  height: 120px;
  overflow-y: hidden;
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  text-align: justify;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .animalsAdmin {
    justify-content: center;
  }
  
  .animalsFilesContainer {
    width: 90%;
    margin-top: 3vh;
    margin-right: 0;
    margin-bottom: 13vh;
  }
  
  .animalsAdminAddButtonLine {
    margin-bottom: 2vh;
  }
  
  .animalsAdminAddButton {
    height: 40px;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
  }
  
  .animalsAdminAddButtonText {
    font-size: 2.3vw;
    margin-left: 1.5vw;
  }
  
  .animalFileContainer {
    margin-left: 1.5%;
    margin-right: 1.5%;
    height: 18vh;
  }
  
  .animalFileContainerOverlayButton {
    width: 70%;
    height: 35px;
  }
  
  .animalFileContainerOverlayButtonText {
    font-size: 1.8vw;
    margin-left: 1.5vw;
  }
  
  .animalFileContainerOverlaySeparator {
    height: 1vh;
  }
  
  .animalFileName {
    font-size: 2.3vw;
    margin-left: 8%;
  }
  
  .animalFileAge {
    font-size: 2vw;
    margin-right: 8%;
  }
  
  .animalFileContainer:hover {
    transform: scale(0.95);
  }
  
  .animalsAdminMainInfoContainer {
    display: none;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  
  .animalsAdminAddButton {
    height: 45px;
    padding-left: 8vw;
    padding-right: 8vw;
  }

  .animalsAdminAddButtonLine {
    margin-bottom: 4vh;
  }
  
  .animalsAdminAddButtonText {
    font-size: 5vw;
    margin-left: 3vw;
  }
  
  .animalFileContainer {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    height: 35vh;
  }

  .animalFileContainerOverlay {
    height: 75%;
  }
  
  .animalFileContainerOverlayButton {
    width: 75%;
    height: 40px;
  }
  
  .animalFileContainerOverlayButtonText {
    font-size: 4vw;
    margin-left: 3vw;
  }
  
  .animalFileContainerOverlaySeparator {
    height: 3vh;
  }

  .animalFileImage {
    height: 75%;
  }

  .animalFileInfo {
    height: 25%;
  }
  
  .animalFileName {
    font-size: 6vw;
    margin-left: 8%;
  }
  
  .animalFileAge {
    font-size: 4.5vw;
    margin-right: 8%;
  }
}