.shelterFormSeparator {
  height: 2vh;
}

.shelterImageUploadContainer {
  height: auto;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #fff;
  border-bottom-style: solid;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1vh;
  padding-top: 1vh;
}

.shelterImageUploadText {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 1.2vw;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 15px;
}

.shelterProfileImg {
  position: absolute;
  left: 5vw;
  top: 25vh;
  width: 10vw;
  height: 10vw;
  border-radius: 5vw;
  object-fit: cover;
  margin-top: 0;
  margin-bottom: 0;
}

.differentZoneCheck {
  width: 100% !important;
  margin-top: 4vh !important;
  margin-bottom: 1vh !important;
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .shelterProfileImg {
    position: static;
    width: 30vw;
    height: 30vw;
    border-radius: 15vw;
    align-self: center;
    margin-bottom: 5vh;
  }
  
  .shelterImageUploadText {
    font-size: 2.2vw;
    margin-left: 15px;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .shelterProfileImg {
    width: 50vw;
    height: 50vw;
    border-radius: 25vw;
    margin-bottom: 7vh;
  }
  
  .shelterImageUploadText {
    font-size: 3vw;
    margin-left: 15px;
  }
}