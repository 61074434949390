.authOverlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authBoxContainer {
  width: 35vw;
  height: 70%;
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.authBoxTitle {
  font-family: 'Avenir Next';
  font-weight: 800;
  color: #333;
  font-size: 1.5vw;
  width: auto;
  text-align: center;
  position: absolute;
  top: 2.2vw;
  left: 2vw;
  margin-bottom: 0;
  margin-top: 0;
}

.closeContainer {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: auto;
  height: auto;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.5s ease-out;
}

.closeContainer:hover {
  transform: rotate(90deg);
}

@media (orientation: portrait) and (max-width: 1024px) {
  
  .authBoxContainer {
    width: 60vw;
    height: 50%;
  }
  
  .authBoxTitle {
    font-size: 2.8vw;
    top: 3vw;
    left: 4vw;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .closeContainer {
    top: 3vw;
    right: 4vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .authBoxContainer {
    width: 90vw;
    height: 90%;
  }
  
  .authBoxTitle {
    font-size: 4.5vw;
    top: 5%;
    left: 5vw;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .closeContainer {
    top: 5%;
    right: 5vw;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}